<template>
	<div class="bg-white dark:bg-t3-nightCard shadow mt-3">

		<nav class="2xl:flex  text-gray-400 dark:text-t3-nightText font-medium">
			<div class="flex flex-col sm:flex-row">
				<button value="booked" @click="filterAssessments"
					class="py-4 px-6 block hover:text-t3 dark:hover:text-white focus:outline-none "
					:class="{ 'text-t3 border-b-2 border-t3-secondary': currTab === 'booked' }">Booked</button>
				<button value="ongoing" @click="filterAssessments"
					class="py-4 px-6 block hover:text-t3 dark:hover:text-white focus:outline-none "
					:class="{ 'text-t3 border-b-2 border-t3-secondary': currTab === 'ongoing' }">Ongoing</button>
				<button value="pending" @click="filterAssessments"
					class="py-4 px-6 block hover:text-t3 dark:hover:text-white focus:outline-none "
					:class="{ 'text-t3 border-b-2 border-t3-secondary': currTab === 'pending' }">Pending</button>
				<button value="completed" @click="filterAssessments"
					class="py-4 px-6 block hover:text-t3 dark:hover:text-white focus:outline-none"
					:class="{ 'text-t3 border-b-2 border-t3-secondary': currTab === 'completed' }">Completed</button>

				<button value="disputed" @click="filterAssessments"
					class="py-4 px-6 block hover:text-t3 dark:hover:text-white focus:outline-none"
					:class="{ 'text-t3 border-b-2 border-t3-secondary': currTab === 'disputed' }">Disputed</button>
				<button value="cancelled" @click="filterAssessments"
					class="py-4 px-6 block hover:text-t3 dark:hover:text-white focus:outline-none"
					:class="{ 'text-t3 border-b-2 border-t3-secondary': currTab === 'cancelled' }">Cancelled</button>
			</div>
			
			<div class="flex ml-auto justify-end w-full py-2 px-6 gap-4 items-center">
				<div class="mx-4">
					<label for="rowscount" class="inline-block mx-2 text-xs 2xl:text-base ">Number of records:</label>
					<select @change="$emit('refreshPage', {curr:currTab,filterString:filterString}, pageNum)" v-model="pageNum" id="rowscount"
						class="form-control w-12 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
						<option value="12">12</option>
						<option value="24">24</option>
						<option value="48">48</option>
						<option value="96">96</option>
					</select>
				</div>
				<button @click="$emit('firstPage', {curr:currTab,filterString:filterString})" v-if="page > 1"
					class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md">
					<div class="flex">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
							stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round"
								d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
						</svg>
					</div>
				</button>
				<button @click="$emit('previousPage', {curr:currTab,filterString:filterString})" v-if="page > 1"
					class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
						stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
					</svg>
				</button>
				<p class="text-t3 font-medium text-xs 2xl:text-base" v-if="pageCount">Page {{ page }} of {{ pageCount }}</p>
				<button @click="$emit('nextPage', {curr:currTab,filterString:filterString})" v-if="isNext"
					class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
						stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
					</svg>
				</button>
				<button @click="$emit('lastPage', {curr:currTab,filterString:filterString}, pageCount)" v-if="isNext"
					class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
						stroke="currentColor" class="w-6 h-6">
						<path stroke-linecap="round" stroke-linejoin="round"
							d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
					</svg>
				</button>

				<div class=" mr-2 my-auto">
					<button v-if="!filterOn" @click="filterOn = true"
						class="flex flex-col rounded-full  items-center font-medium transform  text-center p-2 text-t3  hover:text-t3-teritiary focus:rotate-180">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2"
							stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round"
								d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
						</svg>
					</button>
	
					<button v-if="filterOn" @click="cancelFilter()"
						class="flex flex-col rounded-full p-2 items-center font-medium transform rotate-180 text-center text-t3  hover:text-t3-teritiary focus:rotate-0">
	
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2"
							stroke="currentColor" class="w-6 h-6  ">
							<path stroke-linecap="round" stroke-linejoin="round"
								d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
						</svg>
					</button>
				</div>
			</div>
		</nav>
		<div v-if="filterOn === true" >
			<div  v-for="(filter,index) in filters" :key="index" >
				<div class="px-3 h-16 flex w-full justify-start items-center" v-if="index === 0">
					<label for="filterT" class=" m-2 text-gray-700 text-md font-semibold ">Filter by: </label>
					<select @change="clearFilter(filter)" name="filterT" v-model="filter.filterType"
						class="p-1 form-control w-64 form-control text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out mr-4 focus:outline-none">
						<option value="">Select Filter Type</option>
						<option v-if="scopeCheck('view_all_assessments')" value="active_assessor">by Active Assessor</option>
						<option v-if="scopeCheck('view_all_assessments')" value="inactive_assessor">by Inactive Assessor</option>
						<option value="date">by Date</option>
						<option value="location">by Location</option>.
						<option value="organization">by Organisation</option>
						<option value="license_type">by Licence types</option>
						<option value="final_level">by Level</option>
						<option value="missing">by Missing</option>
					</select>
					<label for="filterT"  class=" m-2 text-gray-700 text-md  font-semibold ">Filter option: </label>
					
					<select v-if="filter.filterType !== 'date'" name="filterT" @change="updateAssessment" v-model="filter.filtering"
						class="p-1 form-control w-64 form-control text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
						<option value="" disabled>Select Option</option>
						<option v-for="field in filterField(filter.filterType)" :key="field.id" :value="field.id">
							<span v-if="filter.filterType === 'active_assessor' || filter.filterType === 'inactive_assessor'">{{ field.full_name }}</span>
							<span v-if="filter.filterType === 'location' || filter.filterType === 'license_type'">{{ field.name }}</span>
							<span v-if="filter.filterType === 'final_level'">{{ field.value }}</span>
							<span v-if="filter.filterType === 'organization'">{{ field.organization_name }}</span>
						</option>
						<option v-if="filter.filterType === 'missing'" value="comments">Comments</option>
						<option v-if="filter.filterType === 'missing'" value="recordings">Attachments</option>
					</select>
					<Datepicker  v-if="filter.filterType === 'date'" v-model="date" @update:modelValue="updateAssessmentDate($event,filter)" range :presetRanges="presetRanges"  />
					<button @click="addFilter()"
						class=" p-2 gap-2 flex items-center font-medium  text-center text-t3 ml-auto hover:text-t3-secondary focus:rotate-0">
								<span class="text-2xl">+</span>Add Filter
					</button>
					<button @click="cancelFilter()"
						class=" p-2 gap-2 flex items-center font-medium  text-center  ml-6 text-t3-teritiary hover:text-t3-cancel focus:rotate-0">
								<span class="text-2xl">X</span>Clear Filters
					</button>
				</div>
				<div class="px-3 h-16 flex w-full justify-start items-center" v-if="index !== 0">
					<label for="filterT" class=" m-2 text-gray-700 text-md font-semibold ">Filter by: </label>
					<select @change="clearFilter(filter)" name="filterT" v-model="filter.filterType"
						class="p-1 form-control w-64 form-control text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out mr-4 focus:outline-none">
						<option value="">Select Filter Type</option>
						<option v-if="scopeCheck('view_all_assessments')" value="active_assessor">by Active Assessor</option>
						<option v-if="scopeCheck('view_all_assessments')" value="inactive_assessor">by Inactive Assessor</option>
						<option value="date">by Date</option>
						<option value="location">by Location</option>
						<option value="organization">by Organisation</option>
						<option value="license_type">by Licence types</option>
						<option value="final_level">by Level</option>
						<option value="missing">by Missing</option>
					</select>
					<label for="filterT"  class=" m-2 text-gray-700 text-md  font-semibold ">Filter option: </label>
					
					<select v-if="filter.filterType !== 'date'" name="filterT" @change="updateAssessment" v-model="filter.filtering"
						class="p-1 form-control w-64 form-control text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
						<option value="" disabled>Select Option</option>
						<option v-for="field in filterField(filter.filterType)" :key="field.id" :value="field.id">
							<span v-if="filter.filterType === 'active_assessor' || filter.filterType === 'inactive_assessor'">{{ field.full_name }}</span>
							<span v-if="filter.filterType === 'location' || filter.filterType === 'license_type'">{{ field.name }}</span>
							<span v-if="filter.filterType === 'final_level'">{{ field.value }}</span>
							<span v-if="filter.filterType === 'organization'">{{ field.organization_name }}</span>
						</option>
						<option v-if="filter.filterType === 'missing'" value="comments">Comments</option>
						<option v-if="filter.filterType === 'missing'" value="recordings">Attachments</option>
					</select>
					<Datepicker  v-if="filter.filterType === 'date'" v-model="date" @update:modelValue="updateAssessmentDate($event,filter)" range :presetRanges="presetRanges"  />
					<button @click="removeFilter(index)"
						class=" p-2 gap-2 flex items-center font-medium  text-center  ml-6 text-t3-cancel hover:text-t3-teritiary focus:rotate-0">
								<span class="text-2xl">-</span>Remove Filter
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="text-xl w-full font-semibold text-center text-t3 my-64" v-if="assessments.length === 0 && !loading">
		<img src="../assets/no-assessments.svg" alt="" class="w-32 m-auto">
		<p v-if="filters[0].filtering ==='' && filters.length === 1">It appears there are no {{ currTab }} assessments right now.</p>
		<p v-if="filters[0].filtering!=='' || filters.length > 1">There are no {{ currTab }} assessments for the assigned filters. </p>
			<!-- <p v-if="filtering && (filterType === 'date' )">There are no {{ currTab }}
			assessments for the selected date range.</p>
		<p v-if="filtering && (filterType === 'assessor' || filterType === 'byCustomer')">There are no {{ currTab }}
			assessments for {{ filterField.find(f => f.id === filtering).full_name }} </p>
		<p v-if="filtering && filterType === 'missing'">There are no {{ currTab }} assessments with missing
			{{ filtering }}. </p> -->
	</div>
	<div class="text-xl w-full font-semibold text-center text-t3 my-64" v-if="assessments.length === 0 && loading">
		<PulseLoader :loading="loading"  color="#00ADEE" size="16px" class="mb-6"></PulseLoader>
		<p >Loading. Please Hold.</p>
		
	</div>

	<div v-show="booking" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 py-3">

		<div v-for="assessment in assessments" :key="assessment.id" tabindex="0" aria-label="card 1"
			class="focus:outline-none bg-white p-6 shadow-t3 rounded space-y-1 dark:bg-t3-nightCard dark:text-white">
			<div class="flex items-center justify-between border-b border-gray-200 pb-5 dark:border-t3-light">
				<router-link :to="{ name: 'AssessmentDetails', params: { id: assessment.id } }" class="flex w-full assessment-link ">
					<img :src="assessment.customer_image" alt="" class="w-14 h-14 rounded-xl" />
					<div class="pl-3 w-5/6 lowercase">
						<p tabindex="0" class="focus:outline-none truncate text-xl font-medium capitalize leading-5">
							{{ assessment.customer_name }}</p>
						<p tabindex="0"
							class="focus:outline-none text-sm leading-normal pt-2 text-gray-500 capitalize dark:text-t3-nightText">
							{{ assessment.customer_id }}<span v-if="assessment.status === 'completed' || assessment.status === 'sealed'"
								class="focus:outline-none text-sm leading-normal pt-2 pl-2 text-gray-500 dark:text-t3-nightText">|
								ELP Level : {{ assessment.final_level }} </span></p>
					</div>
				</router-link>
				<div v-if="scopeCheck('cancel_assessment') || (scopeCheck('update_assessment') && assessment.status === 'booked')"
					role="img" aria-label="bookmark">
					<button
						class="dropdown-toggle py-2.5  text-t3 font-medium text-xs leading-tight uppercase hover:cursor-pointer hover:text-t3-teritiary focus:outline-none focus:ring-0  transition duration-150 ease-in-out flex items-center whitespace-nowrap"
						type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24"
							stroke="currentColor">
							<path
								d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
						</svg>
					</button>
					<ul class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-t3 mt-1 hidden m-0 bg-clip-padding border-none"
						aria-labelledby="dropdownMenuButton1">
						<li v-if="scopeCheck('update_assessment') && assessment.status === 'booked'">
							<button
								class="dropdown-item text-sm py-1 px-4 font-normal flex items-center w-full text-left whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
								:value="assessment.id" @click="rescheduleModal">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
									stroke-width="1.5" stroke="currentColor" class=" pr-3 w-8 h-8">
									<path stroke-linecap="round" stroke-linejoin="round"
										d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
								</svg>Reschedule</button>
						</li>
						<li v-if="scopeCheck('cancel_assessment')">
							<button
								class="dropdown-item text-sm py-1 px-4 font-normal flex items-center w-full text-left whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
								:value="assessment.id" @click="deleteAssess">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
									stroke-width="1.5" stroke="currentColor" class="pr-3 text-t3-cancel w-8 h-8">
									<path stroke-linecap="round" stroke-linejoin="round"
										d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
								</svg>Archive</button>
						</li>
					</ul>
				</div>
			</div>
			<p class="text-sm pt-3 mb-3 text-gray-600 dark:text-t3-nightText">Assigned Assessors</p>
			<div class=" flex justify-between item-start">
				<div class=" flex items-center flex-no-wrap">
					<div v-if="assessmentOPE(assessment.ope)" class="relative h-10 w-10 bg-cover bg-center rounded-md">
						<img  :src="assessmentOPE(assessment.ope).profile_img" alt=""
							class="h-full w-full overflow-hidden object-cover hoverimage rounded-full border-2 border-white dark:border-gray-700 shadow">
							<div data-popover :id="'popover-ope-'+assessment.id" role="tooltip" class="absolute z-40 invisible inline-block w-64 text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
							<div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
								<h3 class="font-semibold text-gray-900 dark:text-white">{{assessmentOPE(assessment.ope).full_name}}</h3>
							</div>
							<div class="px-3 py-2">
								<p>Operational Expert</p>
							</div>
							
						</div>
					</div>
					<div v-if="!assessment.ope" class="relative h-10 w-10 bg-cover bg-center rounded-md">
						<h2 class="text-xs text-t3-mutedText font-semibold text-center">No OPE</h2>
					</div>
					<div v-if="assessmentELE(assessment.ele)" class="relative h-10 w-10 bg-cover rounded-md ml-1">
						<img :data-popover-target="'popover-ele-'+assessment.id" :src="assessmentELE(assessment.ele).profile_img" alt=""
							class="h-full w-full overflow-hidden object-cover hoverimage rounded-full border-2 border-white dark:border-gray-700 shadow">
							<div data-popover :id="'popover-ele-'+assessment.id" role="tooltip" class="absolute z-40 invisible inline-block w-64 text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
							<div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
								<h3 class="font-semibold text-gray-900 dark:text-white">{{assessmentELE(assessment.ele).full_name}}</h3>
							</div>
							<div class="px-3 py-2">
								<p>English Language Expert</p>
							</div>
							
						</div>
					</div>
					<div v-if="!assessment.ele" class="relative h-10 w-10 bg-cover bg-center rounded-md">
						<h2 class="text-xs text-t3-mutedText font-semibold text-center">No ELE</h2>
					</div>
				</div>
				<div class=" text-right">
					<!-- <p class="text-gray-400 dark:text-t3-nightText text-sm sm:ml-3 capitalize">{{assessment.status}}</p> -->
					<p v-if="addresses.find(a => a.id === assessment.location)"
						class="text-gray-400 dark:text-t3-nightText text-sm sm:ml-3 capitalize">{{ addresses.find(a =>
								a.id === assessment.location).name
						}}</p>
					<p class="text-base font-semibold tracking-wide">{{ assessment.start_time }}</p>
					<p class="text-sm tracking-tight">{{ new Date(assessment.date).toLocaleDateString('en-GB', {
							year:
								'numeric', month: 'short', day: 'numeric'
						})
					}}</p>

				</div>
			</div>
		</div>

	</div>

	<div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
		id="rescheduling" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
		<div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
			<div
				class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
				<div
					class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
					<h5 class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel">
						Rescheduling Assessment {{ reassess }}
					</h5>
					<button type="button"
						class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
						data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body relative p-4">
					<label for="datepick" class="text-sm text-gray-400 mt-8">Highlighted dates have available slots
					</label>
					<Datepicker :min-date="new Date()" id="datepick" @update:modelValue="newSlots" v-model="Assess.date"
						:enableTimePicker="false" :highlight="highlighted" datePicker placeholder="Select Date"
						class="mb-8 form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none" />
					<select v-model="reslot"
						class="h-12 form-control block w-full p-3 text-xl overflow-y-scroll font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
						<option disabled :value="null">Select Slot</option>
						<option v-for="slot in freeSlot" :value="slot.id" :key="slot.id">Date:{{ new Date(slot.date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'}) }}
							Time:{{ slot.start_time }}</option>
					</select>

				</div>
				<div
					class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
					<button type="button" @click="cancelRes" id="close"
						class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
						data-bs-dismiss="modal">
						Close
					</button>
					<button type="button" @click="sendRes"
						class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
						Reschedule
					</button>
				</div>
			</div>
		</div>
	</div>
	<button id="ww" type="button" class="hidden" data-bs-toggle="modal" data-bs-target="#rescheduling">
	</button>

</template>

<style scoped>
.hoverimage:hover+div{
	visibility: visible;
	opacity: 100;
} 
</style>

<script>
// import Calendar from '@/components/Calendar.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2';
import ReportServices from '@/services/ReportServices'
import router from '../router'
import { ref, watchEffect } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import AssessmentService from "../services/AssessmentsServices"
import addDays from 'date-fns/addDays';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import 'flowbite/dist/flowbite.min.js';
export default {
	components: {
		Datepicker,
		PulseLoader
	},
	setup() {
		
		const date = ref();
		const presetRanges = ref([
        { 
          label: 'Today', 
          range: [new Date(), new Date()] },
        { 
          label: 'This month', 
          range: [startOfMonth(new Date()), endOfMonth(new Date())] },
        {
          label: 'Last month',
          range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
        },
        { 
          label: 'This year', 
          range: [startOfYear(new Date()), endOfYear(new Date())] },
      ]);
		const highlightedDates = ref([
			addDays(new Date(), 1),
			addDays(new Date(), 2),
			addDays(new Date(), 3),
		])

		return {
			date,
			highlightedDates,
			presetRanges
		}
	},

	data() {
		return {
			
			toSend: "",
			pageNum: null,
			filterString:'',
			filters:[
				{
					filterType:'',
			filtering: '',
				}
			],
			filterOn: false,
			assess: [],
			Assess: {
				date: null
			},
			booking: true,
			freeSlots: null,
			reslot: null,
			reassess: null,
			highlighted: [],
			freeSlot: [],
		}
	},
	props: {
		numberOfPages: {
			type: Number
		},
		page: {
			type: Number
		},
		currTab: {
			type: String}
		,
		loading:{
			type:Boolean,
		}
	},


	methods: {
		deleteAssess(event) {
			let deletedID = event.currentTarget.value
			Swal.fire({
				icon: "warning",
				title: "Hold",
				text: "Are you sure you want to archive this assessment?",
				showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
			}).then((result) => {
				if (result.value) {
					this.deleteassessment(deletedID).then(() => {
						this.$forceUpdate

						Swal.fire({
							icon: "success",
							title: "Success!",
							text: "The assessment got deleted",
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timerProgressBar: true,
							timer: 3000,
						})
						let curro = this.currTab
						if(curro === "completed"){
						curro = "completed&status=sealed"
						}
						this.$emit('updateAssessments', curro)
						this.$forceUpdate()
						document.getElementById("close").click()
					}).catch((error) => {
						let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
					})
				}
			})
		},
		updateAssessmentDate(event,filter){
        let start = new Date(event[0]).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
        let end= new Date(event[1]).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
		if(end==="1970-01-01"){
			end = null
		}
		filter.filtering= {startDate:start,endDate:end}
		this.setFilters(this.filters)
           this.updateAssessment()
        },
		filterAssessments(event) {
			let curro = event.target.value
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
			this.toSend = {curr:curro,filterString:this.filterString}
			router.push({ name: 'AssessmentsTab', params: { currTab: event.target.value } })
			this.$emit('updateAssessments', this.toSend)
		},
		updateAssessment(){
			this.setFilters(this.filters)
			this.filterString=''
			
			this.filters.forEach(filter => {
				if(filter.filterType === 'active_assessor' || filter.filterType === 'inactive_assessor'){
					this.filterString+="&assessor="+filter.filtering
				}
				
				else if(filter.filterType === "date"){
					this.filterString+=("&start_date="+ filter.filtering.startDate + "&end_date=" + filter.filtering.endDate)  }
        else{
            this.filterString+="&"+filter.filterType+"="+filter.filtering
        }
			})
			let curro = this.currTab
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
		
			this.$emit('updateAssessments', {curr:curro,filterString:this.filterString})
		},
		...mapActions({
			getrecordings: 'Recordings/getRecordings',
			getslots: 'Slots/getAllSlots',
			getslotD: 'Slots/getSlotsForDate',
			deleteassessment: 'Assessments/deleteAssessment',
			getdisputes: 'Assessments/getAllDisputes',
			getcustomers: 'Users/getAllCustomers',
			getstaff: 'Users/getAllStaff',
			getactive: 'Users/getStaff',
			getdeleted: 'Users/getDeletedStaff',
			getorganizations: 'Settings/getAllOrganizations',
			getaddresses: 'Settings/getAllAddresses',
			getTypes: 'Settings/getAllCustomerTypes',
			setFilters:'Filters/setFilters'
		}),
		rescheduleModal(event) {
			this.reassess = event.currentTarget.value
			document.getElementById("ww").click()
		},
		sendRes() {
			const getFormData = object => Object.entries(object).reduce((fd, [key, val]) => {
				if (Array.isArray(val)) {
					val.forEach(v => fd.append(key, v))
				} else {
					fd.append(key, val)
				}
				return fd
			}, new FormData())

			AssessmentService.changeSlot(getFormData({ slot: this.reslot }), this.reassess).then(() => {
				Swal.fire({
					icon: "success",
					title: "Success!",
					text: "The assessment got rescheduled to the desired slot",
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timerProgressBar: true,
					timer: 3000,
				})
				let curro = this.currTab
				if(curro === "completed"){
				curro = "completed&status=sealed"
				}
				this.$emit('updateAssessments', {curr:curro,filterString:this.filterString})
				this.$forceUpdate()
				document.getElementById("close").click()
			}).catch((error) => {
				let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
			})
		},
		currentStatus(stat) {
			let cus = ""

			switch (stat) {
				case "completed":
					cus = "w-full bg-t3-secondary"
					break
				case "ongoing":
					cus = "w-2/4 bg-t3-secondary"
					break
				case "booked":
					cus = "w-full  bg-yellow-400"
					break
				case "pending":
					cus = "w-full  bg-yellow-400"
					break
				case "cancelled":
					cus = "w-1.5 bg-t3-cancel"
			}

			return cus
		},
		newSlots(data) {
			if (data) {
				this.Assess.date = data
				let dat = this.Assess.date.toISOString()
				this.getslotD(dat.substr(0, 10)).then(() => {
					if (this.slotD) {
						this.freeSlot = this.slotD.filter(slot => slot.assessment === null)
					}
				}
				)
			}
		},
		assessmentOPE(sentID) {
			return this.allOPE.find(cus => cus.id === sentID)
		},
		assessmentELE(sentID) {
			return this.allELE.find(cus => cus.id === sentID)
		},
		scopeCheck(scope) {
			let user = JSON.parse(localStorage.getItem('userdetail'))

			if (user.is_company) {
				return true;
			} else {
				return localStorage.getItem('scopes').includes(scope)
			}
		},
		clearFilter(filter) {
			filter.filtering = null
			this.setFilters(this.filters)
			this.updateAssessment()
		},
		cancelFilter() {
			this.filters=[{
				filterType:null,
				filtering:null,
			}]
			this.filterOn = false
			this.setFilters(this.filters)
			this.updateAssessment()
		},
		addFilter() {
			this.filters.push({filterType:"",filtering:"",})
			this.setFilters(this.filters)

		},
		removeFilter(index) {
			this.filters.splice(index,1)
			this.setFilters(this.filters)
			this.updateAssessment()
		},
		
		filterField(filterType) {
			if (filterType === "active_assessor") {
			return this.activeAssessors.sort(function (a, b) {
				if (a.full_name < b.full_name) {
					return -1;
				}
				if (a.full_name > b.full_name) {
					return 1;
				}
				return 0;
			})
			}
			else if (filterType === "inactive_assessor") {
				return this.deletedAssessors.sort(function (a, b) {
				if (a.full_name < b.full_name) {
					return -1;
				}
				if (a.full_name > b.full_name) {
					return 1;
				}
				return 0;
			})
			}
			else if (filterType === "location") {
				return this.addresses
			}
			else if (filterType === "organization") {
				return this.organizations
			}
			else if (filterType === "license_type") {
				return this.licenses
			}
			else if (filterType === "final_level") {
				return [
					{
						id:1,
					value:1
				},
					{
						id:2,
					value:2
				},
					{
						id:3,
					value:3
				},
					{
						id:4,
					value:4
				},
					{
						id:5,
					value:5
				},
					{
						id:6,
					value:6
				}]

			}
			else {
				return null
			}
		},
		checkAvail(term){
			if(this.filters.find(filter => filter.filterType === term)){
				return true
			}
			else{
				return false
			}
		}
	},
	computed: {
		// capitalize(){
		// 	return this.assessments.map((assessment) => {
		// 		var separateWord = assessment.customerName.toLowerCase().split(' ');
		// 		for (var i = 0; i < separateWord.length; i++) {
		// 			separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
		// 			separateWord[i].substring(1);
		// 		}
		// 		return separateWord.join(' ');
		// 	})
		// }


		...mapState({
			addresses: state => state.Settings.addresses,
			slotD: state => state.Slots.slotD,
			slots: state => state.Slots.slots,
			organizations: state => state.Settings.organizations,
			customers: state => state.Users.customers,
			assessments: state => state.Assessments.assessments,
			isNext: state => state.Assessments.next,
			counts: state => state.Assessments.count,
			disputes: state => state.Assessments.disputes,
			licenses: state => state.Settings.customer_types,
			recordings: state => state.Recordings.recordings,
			stateFilter: state => state.Filters.filters,
			deletedStaff: state => state.Users.deletedStaff
		}),
		...mapGetters('Users', [
			'customerOnly',
			'staffOnly',
			'Assessors',
			'activeAssessors',
			'deletedAssessors',
			'allOPE',
			'allELE',
			'OPE',
			'ELE'
		]),
		
		pageCount() {
			if (this.counts / this.numberOfPages > 1) {
				return Math.ceil(this.counts / this.numberOfPages)
			}
			else {
				return null
			}
		},


	},

	created() {
		
		this.filters = this.stateFilter
		this.updateAssessment()
		this.pageNum = this.numberOfPages
		this.getTypes()
		this.getrecordings()
		this.getdisputes()
		this.getslots().then(() => {
			this.freeSlots = this.slots.filter(slot => slot.assessment === null)
		})
		if (this.Assess.date) {
			let dat = this.Assess.date.toISOString()
			this.getslotD(dat.substr(0, 10))
		}
		else {
			this.slotD = null
		}
		if (this.slotD) {
			this.freeSlot = this.slotD.filter(slot => slot.assessment === null)

		}
		this.getstaff()
		this.getdeleted()
		this.getactive()
		this.getorganizations()

		watchEffect(() => {
			this.getaddresses()
			// this.getcustomers()
			// if (this.scopeCheck("view_all_assessments")) {
			// 	this.assess = this.assessments.filter(cus => cus.status === this.currTab)
			// }
			// else {
			// 	let user = JSON.parse(localStorage.getItem('userdetail'))
			// 	if (user.customer_type === "ele") {
			// 		this.assess = this.assessments.filter(cus => cus.status === this.currTab && cus.ele === user.id)
			// 	}
			// 	else if (user.customer_type === "ope") {
			// 		this.assess = this.assessments.filter(cus => cus.status === this.currTab && cus.ope === user.id)
			// 	}
			// 	this.assess.concat(this.assessments.filter((cus) => {
			// 		return this.disputes.find(d => d.assessment === cus.id && d.responsible === user.id)
			// 	}))

			// }
			if (this.slots) {
				this.highlighted = []
				this.slots.filter(slot => slot.assessment === null).forEach((s) => {
					this.highlighted.push(new Date(s.date))
				})
			}


		})

	}

}

</script>